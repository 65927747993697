@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic');
@import url('https://fonts.googleapis.com/css?family=Merriweather:400,300,700,900,300italic,400italic,700italic,900italic');
@import url('https://fonts.googleapis.com/css?family=La+Belle+Aurore');

// Overwrite theme hover colour
.btn:hover,
.btn-large:hover {
  background-color: $brand-accent-darker;
}
.btn-outline-secondary:hover {
  background-color: #ccc;
}
.modal-title {
  margin-top: 0;
}