.hero_fullscreen {
  @include media-breakpoint-down(sm) {
    padding-top: 1rem;
  }
}
.row-logo {
  @include media-breakpoint-down(sm) {
    margin-top: 10px;
  }
}

.nav-item{
  &.headway{
    opacity: 1;
    position: relative;
    a {
      opacity: 0.8;
      &:focus{
        color: inherit;
      }
      &:hover{
        color: inherit;
      }
    }
    #HW_badge_cont {
      position: absolute;
      width: 130px;
      top: 3px;
      z-index: 100;
      #HW_badge {
        right:0 ;
        left:auto;
      }
    }
  }
  list-style-type: none !important;
  opacity: 0.8;
  &:first-child{
    margin-right:20px;
  }
  a {
    font-size: 16px;
    font-weight: bold;
  }
}

.hero_fullscreen.background_solid.solid_02{
  height:100vh;
}

.logo_home {
  margin-bottom: 0;
}

.font-handwrite{
  font-weight: 700;
  font-family: 'La Belle Aurore', cursive;
  margin-bottom: -15px;
  word-spacing: -1rem;
  color: $brand-accent;
  margin-left: 50px;
  @include media-breakpoint-up(sm) {
    margin-left: 200px;
  }
}
.slogan {
  margin-top:0;
}

.feature_box.feature_box_final {
  border-bottom: none;
}



.strike {
  position: relative;
  display: inline-block;
}
.strike::before {
  content: '';
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
}
.strike::before {
  border-bottom: 4px solid $brand-accent;;
  -webkit-transform: skewY(-10deg);
  transform: skewY(-10deg);
}

p.description {
  font-size: 1.2rem;
}

p.launch-description {
  opacity: 1;
  .launch-text {
    opacity: 0.64;
  }
}

.smiley {
  opacity: 1;
}

#more_info {
  ul {
    font-size: 1.2rem;
    display: inline-block;
    opacity: 0.64;
    list-style-type: circle;
    text-align: center;
    li {
      text-align: left;
    }
  }
}

// Style from MailerLight for Subscribe form popup
.ml-button-iframe {
  background-color: transparent !important;
  border: 0px none transparent !important;
  overflow: hidden !important;
  position: fixed !important;
  visibility: visible !important;
  margin: 0px !important;
  padding: 0px !important;
  left: 0px !important;
  top: 0px !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 9999 !important;
  display: none;
}

#footer.subsection {
  padding-top: 0;
}

.modal-body {
  ol {
    font-size: 1.2rem;
    display: inline-block;
    opacity: 0.64;
    text-align: center;
    li {
      text-align: left;
    }
  }
}