/*------------------------------------------------------------------
[Master Stylesheet]

Project:	    SPOT - Landing Page Template
Version:	    1.0
Last change:	28/04/2015
Author:         Mat Przegietka 
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Table of contents]

1.  Helper Classes
2.  Preloader
3.  Icons
4.  Navigation
5.  Homepage Content
6.  Icon Box
7.  Intro
8.  Subsections
9.  Pricing Table
10. Team
11. Contact Form
12. Inputs
13. Buttons

-------------------------------------------------------------------*/


/*--------------------------------------*/
/* 1. Helper Classes -------------------*/
/*--------------------------------------*/

.align-center {text-align:center}
.aligh-left {text-align:left}
.align-right {text-align:right}
.float-left {float:left}
.img_responsive {max-width: 100%}
.img-circle {border-radius: 50%}
.img-rounded_corner {border-radius: 0.2rem}


/*--------------------------------------*/
/* 2. Preloader ------------------------*/
/*--------------------------------------*/

.preloader_header {
    position: fixed;
    top: 0;
    z-index: 100;
    min-height: 480px;
    width: 100%;
    height: 100%;
}

.preloader_loader {
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0;
    cursor: default;
    pointer-events: none;
}

.preloader_loader {
    bottom:20%;	
}

.preloader_header .preloader_inner {
    display: block;
    margin: 0 auto;
}

.preloader_header .preloader_loader svg path {
    fill: none;
    stroke-width: 6;
}


/* Animations */
/* Initial animation of header elements */

.loading .preloader_loader {
	opacity: 1;
	-webkit-animation: animInitialHeader 1s cubic-bezier(0.7,0,0.3,1) both;
	animation: animInitialHeader 1s cubic-bezier(0.7,0,0.3,1) both;
}

.loading .preloader_loader {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

@-webkit-keyframes animInitialHeader {
	from { opacity: 0; -webkit-transform: translate3d(0,800px,0); }
}

@keyframes animInitialHeader {
	from { opacity: 0; -webkit-transform: translate3d(0,800px,0); transform: translate3d(0,800px,0); }
}


/* Header elements when loading finishes */

.loaded .preloader_loader {
	opacity: 1;
}

.loaded .preloader_loader {
	-webkit-animation: animLoadedLoader 0.5s cubic-bezier(0.7,0,0.3,1) forwards;
	animation: animLoadedLoader 0.5s cubic-bezier(0.7,0,0.3,1) forwards;
}

@-webkit-keyframes animLoadedLoader {
	to { opacity: 0; -webkit-transform: translate3d(0,-100%,0) scale3d(0.3,0.3,1); }
}

@keyframes animLoadedLoader {
	to { opacity: 0; -webkit-transform: translate3d(0,-100%,0) scale3d(0.3,0.3,1); transform: translate3d(0,-100%,0) scale3d(0.3,0.3,1); }
}


/* Header animation when loading finishes */

.loaded .preloader_header {
	-webkit-animation: animLoadedHeader 1s cubic-bezier(0.7,0,0.3,1) forwards;
	animation: animLoadedHeader 1s cubic-bezier(0.7,0,0.3,1) forwards;
}

@-webkit-keyframes animLoadedHeader {
	to { -webkit-transform: translate3d(0,-100%,0); }
}

@keyframes animLoadedHeader {
	to { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
}


/* Content animations */

.loaded .home_anim1,
.loaded .home_anim2,
.loaded .home_anim3,
.loaded .home_anim4,
.loaded .home_anim5,
.loaded .home_anim6,
.loaded .home_anim7,
.loaded .home_anim8,
.loaded .home_anim9,
.loaded .home_anim10
{
	animation: animLoadedContent 0.8s cubic-bezier(0.7,0,0.3,1) both;
    -webkit-animation: animLoadedContent 0.8s cubic-bezier(0.7,0,0.3,1) both;
}
 
.loaded .home_anim1 {
    animation-delay: 0.1s;
    -webkit-animation-delay: 0.1s;  
}

.loaded .home_anim2 {
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;   
}

.loaded .home_anim3 {
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;   
}

.loaded .home_anim4{
    animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;   
}

.loaded .home_anim5 {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;    
}

.loaded .home_anim6 {
    animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;    
}

.loaded .home_anim7 {
    animation-delay: 0.7s;
    -webkit-animation-delay: 0.7s;    
}

.loaded .home_anim8 {
    animation-delay: 0.8s;
    -webkit-animation-delay: 0.8s;    
}

.loaded .home_anim9 {
    animation-delay: 0.9s;
    -webkit-animation-delay: 0.9s;    
}

.loaded .home_anim10 {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;    
}

@keyframes animLoadedContent {
    from { 
        opacity: 0; 
        transform: translate3d(0,50px,0);  
    }
}

@-webkit-keyframes animLoadedContent {
    from { 
        opacity: 0; 
        -webkit-transform: translate3d(0,50px,0);  
    }
}



/* Mockup animations */

.loaded .mockup-animation1,
.loaded .mockup-animation2
{
	animation: MockupAnimLoadedContent 0.7s cubic-bezier(0.5,.01,.51,1) both;
    -webkit-animation: MockupAnimLoadedContent 0.7s cubic-bezier(0.5,.01,.51,1) both;
}

.loaded .mockup-animation2 {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;   
}

.loaded .mockup-animation1 {
    animation-delay: 1.35s;
    -webkit-animation-delay: 1.35s;   
}
 
@keyframes MockupAnimLoadedContent {
    from { 
        opacity: 0; 
        transform: translate3d(0,50px,0);  
    }
}

@-webkit-keyframes MockupAnimLoadedContent {
    from { 
        opacity: 0; 
        -webkit-transform: translate3d(0,50px,0);  
    }
}

.layout-switch .ip-header {
    position: absolute;
}

.header-none {
	display:none;
}


/*--------------------------------------*/
/* 3. Icons ----------------------------*/
/*--------------------------------------*/

.icon_big {
	font-size: 1.72rem;
	margin:1rem;	
}

.icon_normal {
	width: 2rem;
    font-size: 1.72rem;
}

.icon_small {
	margin:1rem;
	font-size:1.4rem;
	line-height:1.2rem;
	opacity:0.54;
	margin-right:2rem;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}

.icon_list {
	margin-bottom:1.72rem;
}

.icon_list li{
	vertical-align: middle;
    display: table;
}

.icon_list li div,
.icon_list li h6 {
	vertical-align: middle;
    display: table-cell; 
}


/*-- Social Icons --*/

.social_icon{
    font-size:1rem;
    line-height:1rem;
    opacity:0.5;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.social_icon:hover {
    cursor:pointer;
    opacity:1;
}

.social_icons ul, .social_icons ul li {
	display: inline-block;
	list-style: none;
	padding: 0;
	height:2rem;
}

.social_icons ul li {
	margin-right: 0.7rem;
	margin-left: 0.7rem;
}

.social_icons ul li:first-child {
	margin-left:0;
}

.social_icons ul li:last-child {
	margin-right:0;
}

.social_icons ul li {
    float:left;
}

.social_icons_container {
	position: relative;
	width:100%;
    z-index: 10;
}


/*--------------------------------------*/
/* 4. Navigation -----------------------*/
/*--------------------------------------*/

.navigation_desktop ul {
    float:right;
    padding:0;
    margin:0;   
}

.navigation_desktop ul li {
    float:left;
    padding:0;
    height:auto;
    margin-left:1rem;
    margin-right:1rem;
}

.navigation_desktop ul li a {
	font-size: 0.857rem;
	line-height: 0.857rem;
	opacity: 0.5;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.navigation_desktop a:hover {
	opacity:1;
}

.navigation_desktop li a:focus, .navigation_desktop a:hover {
	outline:none;
	text-decoration: none;
}
.navigation_desktop ul li:nth-last-child(2)
.navigation_desktop ul li:last-child {
    margin-right:0rem;
}

.top_bar {
	position:relative;
    z-index: 10;
}


/*-- menu_bar --*/

.menu_bar {
	height:5.14rem;
	padding-top:1.7rem;
	-webkit-box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 1);
	-moz-box-shadow:    0px 0px 3px 0px rgba(50, 50, 50, 1);
	box-shadow:         0px 0px 3px 0px rgba(50, 50, 50, 1);
	position: fixed;
	top:0;
	left: 0;
	width: 100%;
	z-index:1000;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	-webkit-transform: translateY(-105%);
	-moz-transform: translateY(-105%);
	transform: translateY(-105%);
}

.menu_bar-hide {
	height: 5.14rem;
	-webkit-transform: translateY(-105%);
	-moz-transform: translateY(-105%);
	transform: translateY(-105%);
}

.menu_bar-show {
	height: 5.14rem;
	-webkit-transform: translateY(0%);
	-moz-transform: translateY(0%);
	transform: translateY(0%);
}

.menu_bar_navigation ul {
    float:right;
    padding:0;
    margin:0;
    line-height: 0;   
}

.menu_bar_navigation ul li {
    float:left;
    padding:0;
    height:auto;
    margin-left:1rem;
    margin-right:1rem;
}

.menu_bar_navigation ul li:nth-last-child(2),
.menu_bar_navigation ul li:last-child {
    margin-right:0rem;
}

.menu_bar_navigation ul li a {
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-weight:900;
	font-size: 0.75rem;
	line-height: 1.72rem;
	opacity: 0.3;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.menu_bar_navigation ul li a:hover {
	opacity:1
}

.menu_bar_navigation ul li.active a{
	opacity:1;
}

.logo {
	float:left; 
	line-height: 0; 
	margin-top:0.1rem;
}

/*-- Mobile Menu --*/

.cbp-spmenu {
	position: fixed;
	padding-top:2rem;
	background-color: #424242;
	-webkit-box-shadow: -1px 0px 7px 0px rgba(50, 50, 50, 1);
	-moz-box-shadow:    -1px 0px 7px 0px rgba(50, 50, 50, 1);
	box-shadow:         -1px 0px 7px 0px rgba(50, 50, 50, 1);
}

.cbp-spmenu a {
	display: block;
	font-size: 1rem;
	line-height: 1.2rem;
    text-decoration:none;
    text-align:left; 
    opacity:0.5;
    text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-weight:900;	
    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}

.cbp-spmenu a:hover {
    opacity:1;	
}

.cbp-spmenu-vertical {
	width: 200px;
	height: 100%;
	top: 0;
	z-index: 1001;
}

.cbp-spmenu-vertical a {
    padding:0.4rem;
    padding-left:2rem;
}

.cbp-spmenu-right {
	right: -210px;
}

.cbp-spmenu-right.cbp-spmenu-open {
	right: 0px;
}

.cbp-spmenu,
.cbp-spmenu-push {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}


/*-- Mobile Menu open & close buttons --*/

.mobile_menu_open_button {
	margin-top:0.5rem;
}

.mobile_nav_close_button {
	position:absolute;
	z-index: 100;
	right:2rem;
	top:2rem;
	border:0;
}

.mobile_nav_close_button a  {
	border:0;
	color:white;
	font-size:2rem !important;
    line-height:2rem;
    opacity:0.5 !important;
    -webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.mobile_nav_close_button a:hover  {
	opacity:1 !important;
	margin-left:0rem !important;
}

.mobile_nav_open_button a {
	border:0;
	color:white;
	font-size:2rem !important;
    line-height:2rem;
}

.mobile_nav_open_button {
	opacity:0.5;
    -webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.mobile_nav_open_button:hover {
	opacity:1
}

#mobile_menu_content {
    clear:both;
    box-shadow:none;
    -webkit-box-shadow: none;
}


/*--------------------------------------*/
/* 5. Homepage Content -----------------*/
/*--------------------------------------*/

html {
	overflow-x:hidden;
}

.logo_home {
	margin-bottom: 2rem;
}

.hero_fullscreen {
	padding-top:3.428rem;
	padding-bottom: 7rem;
}


/*-- Main Content --*/

#main_content {
	overflow:hidden;
	
}

#main_content h2, 
#main_content h6,
.cta_button_area {
	position:relative;
	z-index:2
} 

.content_container {
	vertical-align: middle;
	z-index: 10;
}
.home_mockup {
	position:relative;
	display:block;
	text-align:center;
	float:right;
	display:none;
}

.home_mockup_image {
	position:absolute;
	right:0%;
	top:-23rem;
	width:100%
}


/*-- CTA Area --*/

.cta_button_area {
	margin-top:3.44rem;
	margin-bottom:3rem;
}

.cta_button_area a{
	opacity:0.5;
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.cta_button_area a:hover {
	opacity:1
}


/*-- Available Area --*/

.available_area {
	position:relative;
	z-index: 10;
}

.available_area h6,
.available_area ul {
	display:inline-block;
	opacity:0.5;
}

.available_area ul li img {
	width:1rem;
	margin:0;
	margin-left: 0.3rem;
	margin-right:0.3rem;	
}

.available_area ul li {
	float:left;
	line-height:0;
}

.available_area ul {
	margin:0 0.2rem;
}


/*-- Backgrounds --*/

#maximage_slider {
	z-index:0;
	opacity:0.4;
	display:none;
}

#maximage_video {
	z-index:0;
	opacity:0.6;
	display:none;
}

.mobile_novideo {
	top: 0;
	left: 0;
	position: absolute;
	width:100%;
	height:100%;
}

#maximage_video{
	background: url('/img/landing/video.jpg') no-repeat 0 0;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

#maximage_single {
	z-index:0;
	display:none;
}


video, object {
	top:0;left:0;
	position:absolute;
	z-index:1;
	opacity:0.3;
}


/*-- Home Content Background Variants --*/
.hero_fullscreen.background_slider #maximage_slider { display:block }
.hero_fullscreen.background_video #maximage_video { display:block }
.hero_fullscreen.background_single #maximage_single { display:block }

.hero_fullscreen.background_slider .logo_home,
.hero_fullscreen.background_video .logo_home,
.hero_fullscreen.background_single .logo_home{
	margin-bottom:12rem; 
}

.hero_fullscreen.background_slider,
.hero_fullscreen.background_video,
.hero_fullscreen.background_single {
	padding-bottom:12rem;
}


/*--------------------------------------*/
/* 6. IconBox --------------------------*/
/*--------------------------------------*/

.iconbox {
	overflow: auto;
	margin-bottom:1rem;
}

.iconbox_icon {
	font-size:2rem;
	line-height:2rem;	
	margin:0 auto;
}

.iconbox_icon.icon_rounded {
	padding:1rem;
	border-radius:100%;
	width:5rem;
	height:5rem;
	font-size:2rem;
	line-height:3.8rem;
	margin:2rem auto;	
}


/*--------------------------------------*/
/* 7. Intro ----------------------------*/
/*--------------------------------------*/

.intro {
	padding-bottom:1rem;
	padding-top:1rem;
}

.intro h3 {
	margin-top:0;
}


/*--------------------------------------*/
/* 8. Subsections ----------------------*/
/*--------------------------------------*/

.subsection {
	padding-top:4rem;
	padding-bottom:4rem;
	position:relative;
	z-index:1;
}


/*-- review logos --*/

#reviews_logos.subsection {
	padding-top:3rem;
	padding-bottom: 1rem;
}

#reviews_logos.subsection h6 {
	opacity:0.5
}


/*-- features --*/

.feature_box .feature_content {
	padding:2rem;
	padding-top:8rem;
}

.feature_content h6 {
	margin-bottom:0.8rem;
}

.feature_image {
	margin-top:5rem;
	padding-bottom:3rem;
}

.feature_box_center .feature_content {
	padding:1rem;
}

.feature_box_center_content_row {
	padding-top:12rem;
}


/*-- about - video --*/

.about_video_player iframe {
	border: solid 0.8rem white;
}

.about_video_player {
	padding-top: 3rem;
	padding-bottom:3rem;
}


/*-- screenshots gallery --*/

.screenshot {
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-bottom:1.5rem;
}

.screenshot:hover {
	opacity:0.8;
}

.screenshots_gallery {
	margin-top:2rem;
}


/*-- client reviews --*/

.single_review {
	margin-bottom:2rem
}

.single_review img{
	max-width:4rem;
	height:4rem;
	margin-top:2rem;
	margin-bottom:2rem;
}

.review_content {
	overflow:hidden;
}

.review_content p {
	padding-top:2rem;
}

.reviews_logo img {
	width:90%
}

.review_content h4 {
	font-style: italic;
}

.review_top_ornament_line {
	margin-left:0.5rem;
	margin-right:0.5rem;
	width:6rem;
	display:inline-block;
}

.review_top_ornament_quotemark {
	font-family:'Times New Roman', Times, Baskerville, Georgia, serif;
	font-weight: bold;
	font-size: 5rem;
	display:inline-block;
	margin-bottom:0.8rem;
}

.review_bottom_ornament_line {
	margin-left:0rem;
	margin-right:0rem;
	width:4rem;
	display:inline-block;
}

.review_bottom_ornament_triangle {
	width: 0;
	height: 0;
    position:relative;
	display:inline-block;
	top:9px;
	margin-left: -5px;
	margin-right:-5px;
}

.review_bottom_ornament_triangle:after {
	content:'';
    width: 0; 
    height: 0;  
    position:absolute;
	top:-10px;
	left:-9px;
}


/*-- footer --*/

#footer.subsection {
	padding-top:2rem;
	padding-bottom:0.9rem;
}

#footer small {
	font-weight: 300
}

/*--------------------------------------*/
/* 9. Pricing Tables -------------------*/
/*--------------------------------------*/

.pricing_box {
	padding:2rem;
	padding-bottom:2.5rem;
	margin-top:2rem;
	margin-bottom:1rem;
}

.pricing_box .iconbox_icon {
	margin-bottom:1rem;
}

.pricing_box_price h3, .pricing_box_price h4{
	display:inline-block;
	margin: 0;
}

.pricing_box_price {
	margin-top:2rem;
	margin-bottom:2rem;
	padding:1rem;
}

.pricing_box_list li p {
	margin:0;
	padding:0;
}

.pricing_box_list li {
	padding-bottom:.5rem;
}



/*--------------------------------------*/
/* 10. Team -----------------------------*/
/*--------------------------------------*/

.team_member_info h4{
	margin-bottom:1rem;
}

.team_member_info {
	padding-bottom:2rem;
	margin-bottom:1rem;
}


/*--------------------------------------*/
/* 11. Contact Form --------------------*/
/*--------------------------------------*/

.contact_form .input-field {
	margin-bottom: 1.72rem;
}

.contact_form_container {
	padding-bottom:2rem;
}

/*-- CONTACT FORM MESSAGE STYLING --*/

#message {
    display:none;
    position: fixed;
    z-index:3000;
    width:40rem;
    top:50%;
    left:50%;
    margin-top:-8.5rem;
    margin-left:-20rem;
    text-align:center;  
}

#alert {
	display:table-cell;
	vertical-align: middle;
    padding-bottom:2rem;
    padding-top:2rem;
    padding-left:2rem;
    padding-right:2rem;
}

.alert_icon {
	font-size:2rem;
	color:white;
}

.unordered li {
    margin:0 !important;
}

.contact_icon {
	font-size:2rem;
	margin-top:4rem;
	margin-bottom:1rem;
}

#message.warning {
	background:#F44336;
}

#message.success {
	background:#4CAF50;
}


/*--------------------------------------*/
/* 12. Inputs ---------------------------*/
/*--------------------------------------*/

.input-field {
	position: relative; 
}

.input-field label {
    opacity:0.7;
    position: absolute;
    top: 0.8rem;
    left: 0rem;
    font-size: 1rem;
    cursor: text;
    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    -ms-transition: 0.2s ease-out;
    transition: 0.2s ease-out; 
}

.input-field label.active {
    font-size: 0.8rem;
    opacity:0.7;
    -webkit-transform: translateY(-130%);
    -moz-transform: translateY(-130%);
    -ms-transform: translateY(-130%);
    -o-transform: translateY(-130%);
    transform: translateY(-130%); 
}

.input-field input[type=text].valid,
.input-field input[type=password].valid,
.input-field input[type=email].valid,
.input-field input[type=date].valid,
.input-field textarea.valid {
    border-bottom: 1px solid #4CAF50;
    -webkit-box-shadow: 0 1px 0 0 #4CAF50;
    -moz-box-shadow: 0 1px 0 0 #4CAF50;
    box-shadow: 0 1px 0 0 #4CAF50; 
}

.input-field input[type=text].invalid,
.input-field input[type=password].invalid,
.input-field input[type=email].invalid,
.input-field input[type=date].invalid,
.input-field textarea.invalid {
    border-bottom: 1px solid #F44336;
    -webkit-box-shadow: 0 1px 0 0 #F44336;
    -moz-box-shadow: 0 1px 0 0 #F44336;
    box-shadow: 0 1px 0 0 #F44336;
}
       
.input-field .prefix {
    position: absolute;
    width: 2rem;
    top:1rem;
    font-size: 1.72rem;
    -webkit-transition: color 0.2s;
    -moz-transition: color 0.2s;
    -o-transition: color 0.2s;
    -ms-transition: color 0.2s;
    transition: color 0.2s; 
}

.input-field .prefix ~ input,
.input-field .prefix ~ textarea {
    margin-left:3rem;
    width: 92%;
    width: calc(100% - 3rem);
}

.input-field .prefix ~ textarea {
    padding-top: 0.8rem; 
}

.input-field .prefix ~ label {
    margin-left: 3rem; 
}
    
input[type=text],
input[type=password],
input[type=email],
input[type=date],
textarea {
	background-color: transparent;
	border: none;
	outline: none;
	height: 3rem;
	width: 100%;
	font-size: 1rem;
	margin: 0 0 15px 0;
	padding: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-o-transition: 0.3s;
	-ms-transition: 0.3s;
	transition: 0.3s;
}

textarea {
	overflow-y: hidden;
	/* prevents scroll bar flash */
	padding: 1.6rem 0;
	/* prevents text jump on Enter keypress */
	resize: none;
}

.hiddendiv {
	display: none;
	white-space: pre-wrap;
	word-wrap: break-word;
	overflow-wrap: break-word;
	/* future version of deprecated 'word-wrap' */
	padding-top: 1.2rem;
	/* prevents text jump on Enter keypress */
}

 
/*--------------------------------------*/
/* 13. Buttons --------------------------*/
/*--------------------------------------*/ 
 
.btn,
.btn-large,
.btn-flat {
	display: inline-block;
	font-weight:900;
	height: 3.2rem;
	padding: 0 2.1rem;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	border-radius: 100px;
	line-height: 3.2rem;
	text-transform: uppercase;
	border: none;
	-webkit-tap-highlight-color: transparent;
}

.btn,
.btn-large {
	text-decoration: none;
	color: #FFF;
	text-align: center;
	letter-spacing: 0.1rem;
	font-size: 0.92rem;
	-webkit-transition: 0.2s ease-out;
	-moz-transition: 0.2s ease-out;
	-o-transition: 0.2s ease-out;
	-ms-transition: 0.2s ease-out;
	transition: 0.2s ease-out;
	cursor: pointer;
}

.btn-flat {
	box-shadow: none;
	background-color:rgba(255, 255, 255, .1);
	color: white;
	cursor: pointer;
}
  
.btn-flat.disabled {
	color: white;
}

.btn-large {
	height: 54px;
	line-height: 56px;
}
.btn-large i {
	font-size: 1.6rem; 
}

.store_button {
	display: inline-block;
	margin:0 0.5rem;
	margin-bottom:1rem;
}

.btn.btn_with_icon {
	line-height:2rem;
	padding: 0 1.5rem;
	padding-top:0;
	padding-bottom:0;
	padding-left:0.8rem;
	padding-right:1.5rem;
	text-align:left !important;
}

.btn.btn_with_icon img{
	height:3.2rem;
	width:3.2rem;
	line-height:3.2rem;
	padding: 0.7rem;
	float:left;
}

.btn.btn_with_icon h6, .btn.btn_with_icon span {
	margin:0 !important;
	padding:0 !important;
	line-height: 0;
}

.btn.btn_with_icon span {
	font-weight: 300;	
	font-size: 0.6rem;
}

.btn.btn_with_icon .btn_content {
	display:inline-block;
}


.z-depth-1, nav, .card-panel, .card, .toast,  .btn-large, .btn-floating, .dropdown-content, .collapsible, ul.side-nav.full, ul.side-nav.fixed, .shadow {
	-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	-moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-1-half, .btn-large:hover, .btn-floating:hover, .modal {
	-webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15); 
}

.z-depth-2, .shadow:hover , .about_video_player iframe{
	-webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-moz-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
}

.z-depth-3 {
	-webkit-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
	-moz-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
	box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19); 
}

.z-depth-4 {
	-webkit-box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
	-moz-box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
	box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21); 
}

.z-depth-5 {
	-webkit-box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
	-moz-box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
	box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22); 
}
	
 
/* Remove Focus Boxes */
select:focus {
    outline: 1px solid #f8c1c3;
}

button:focus {
    outline: none;
}

label {
	font-size: 0.8rem;
	color: #9e9e9e;
} 
  






