@import "common/variables";

// Import vendor styles
// <link href="css/grid12.css" rel="stylesheet" media="screen">                              	 <!-- Grid System -->
//@import "vendor/james_typography";                        // James Framework - TYPOGRAPHY
@import "vendor/main";                                    // Main CSS File
@import "vendor/color_scheme_light";                // Color scheme (dark/light)
@import "vendor/colors/color_palette_cyan";   // Color palette
//@import "vendor/rapid-icons";                       // James Framework - ICONS
//@import "vendor/js_styles/jquery.maximage.min";     // MaxImage background image slideshow
@import "vendor/responsivity";                      // Responsive Fixes
@import "vendor/animate";                           // Animate - animations
//@import "vendor/nivo-lightbox";                     // Lightbox Styles
//@import "vendor/nivo_lightbox_themes/default/default";      // Lightbox Styles
@import "vendor/owl.carousel";                      // Owl - Carousel
@import "vendor/owl.theme";                         // Owl - Carousel
@import "vendor/owl.transitions";                   // Owl - Carousel

// Import npm dependencies
@import "vendor/bootstrap/scss/bootstrap";

// Import app styles
@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/frontpage";

// Import vendor styles
@import "vendor/james_typography";                        // James Framework - TYPOGRAPHY
