/*------------------------------------------------------------------
[Stylesheet]
Color Scheme - Light
-------------------------------------------------------------------*/


.hero_fullscreen,
nav a,
#message,
.cta_button_area a{
	color:white;
}

.hero_fullscreen {
	background-color: #212121;	
}

.preloader_header,
.nivo-lightbox-theme-default.nivo-lightbox-overlay {
	background-color: #fafafa;	
}

.preloader_header .preloader_loader svg path.preloader_loader_circlebg {
    stroke: rgba(0, 0, 0, .1);
}

.preloader_header .preloader_loader svg path.preloader_loader_circle {
    transition: stroke-dashoffset 0.2s;
    stroke: rgba(0, 0, 0, .3);
}

.contact_form .input-field label,
.menu_bar_navigation ul li a{
	color:black;
}
 
.contact_form input[type=text],
.contact_form input[type=password],
.contact_form input[type=email],
.contact_form input[type=date],
.contact_form textarea {
	border-bottom: 1px solid rgba(0, 0, 0, .4);
}

.input-field label {
	color:white;
}

.menu_bar {
	background-color:white;
}

.logo_dark {
	display:block;
}

.logo_light {
	display:none;
}

.iconbox_icon {
    color: rgba(0,0,0,1);
}

.iconbox_icon.icon_rounded,
.pricing_box {
    border: solid 1px rgba(0,0,0,0.1);
}

.pricing_box_price {
	border-top: solid 1px rgba(0,0,0,0.1);
	border-bottom: solid 1px rgba(0,0,0,0.1);
}

.feature_box, .team_member_info {
	border-bottom: solid 1px rgba(0,0,0,0.1);
}

.nivo-lightbox-theme-default .nivo-lightbox-close,
.nivo-lightbox-theme-default .nivo-lightbox-nav {
	color:black
}

#reviews .owl-theme .owl-controls .owl-page span {border-color:black !important; background: rgba(0,0,0,0)}
#reviews .owl-theme .owl-controls .owl-page.active span,
#reviews .owl-theme .owl-controls.clickable .owl-page:hover span {
	background: rgba(0,0,0,1);
	border-color:black !important
}

.background_color1 {
	background:white;
}

.background_color2 {
	background: #fafafa;
}

.review_top_ornament_line {border-top:solid 1px rgba(0,0,0,.1)}
.review_top_ornament_quotemark {color:rgba(0,0,0,.1)}
.review_bottom_ornament_line {border-top:solid 1px rgba(0,0,0,.1)}
.review_bottom_ornament_triangle {
	border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(0,0,0,.16);
}

#reviews.background_color1 .review_bottom_ornament_triangle:after {
	border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid white; 
}

#reviews.background_color2 .review_bottom_ornament_triangle:after {
	border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #fafafa; 
}
